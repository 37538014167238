import { useClearRefinements, useRefinementList } from 'react-instantsearch-core'

import { useAnalyticsTracking } from '@/hooks'

type TransformItemsType = Parameters<typeof useRefinementList>[0]['transformItems']

type UseFacetProps = {
  attribute: string
  transformItems?: TransformItemsType
  sortBy?: Parameters<typeof useRefinementList>[0]['sortBy']
}

export const useFacet = ({ attribute, sortBy, transformItems }: UseFacetProps) => {
  const { trackFilterEvent } = useAnalyticsTracking()
  const { items, refine, isShowingMore, canToggleShowMore, toggleShowMore } = useRefinementList(
    {
      attribute,
      transformItems,
      sortBy,
      limit: 99,
    },
  )
  const { refine: clearRefinements, canRefine } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const handleItemSelect = (value: string) => {
    const filterName = attribute.split('.')[0] // Remove language and lvl info from attribute if present
    trackFilterEvent(filterName, value)
    refine(value)
  }

  return {
    items,
    canRefine,
    handleItemSelect,
    isShowingMore,
    canToggleShowMore,
    toggleShowMore,
    clearRefinements,
  }
}
