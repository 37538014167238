import { useClearRefinements, useInstantSearch } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import { FilterChip } from '@knauf-group/ct-designs/components/core/FilterChip'
import {
  useAnalyticsTracking,
  useAnalyticsTrackResetFilters,
} from 'src/hooks/analytics/useAnalyticsTracking'
import { useFacet } from 'src/hooks/useFacet'

type SelectedItem = Parameters<typeof FilterChip>[0]['options'][number]

type MultiselectFilterChipProps = {
  attribute: string
  label: string
  hasSearch: boolean
  translateOptionFn?: (str: string) => string
  includedAttributesForAnalytics: string[]
}

export const MultiselectFilterChips = ({
  attribute,
  label,
  hasSearch,
  translateOptionFn = (option) => option,
  includedAttributesForAnalytics,
}: MultiselectFilterChipProps) => {
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.filtersPanel' })

  const facet = useFacet({ attribute, sortBy: ['count:desc', 'name:asc'] })

  const { refine: clearRefinements } = useClearRefinements({ includedAttributes: [attribute] })

  const selectedValues = facet.items.filter((item) => item.isRefined).map((item) => item.value)
  const options = facet.items.map((item) => ({
    value: item.value,
    label: translateOptionFn(item.label),
  }))

  const { setIndexUiState } = useInstantSearch()

  const { trackFilterEvent } = useAnalyticsTracking()

  const onClickApply = (selectedItems: SelectedItem[]) => {
    const selectedItemValues = selectedItems.map((item) => item.value)

    setIndexUiState((state) => ({
      ...state,
      refinementList: {
        ...state.refinementList,
        [attribute]: selectedItemValues,
      },
    }))

    trackFilterEvent(attribute, selectedItemValues.join(';'))
  }

  const { trackResetFiltersEvent } = useAnalyticsTrackResetFilters({
    includedAttributes: includedAttributesForAnalytics,
  })

  const onClickReset = () => {
    trackResetFiltersEvent(t('buttons.reset'))

    clearRefinements()
  }

  if (facet.items.length === 0) return null
  return (
    <FilterChip
      key={attribute}
      label={label}
      options={options}
      selectedValues={selectedValues}
      multiple
      hasSearch={hasSearch}
      actionsProps={{
        ApplyButtonProps: { label: t('buttons.apply'), onClick: onClickApply },
        ResetButtonProps: { label: t('buttons.reset'), onClick: onClickReset },
      }}
      dataCy={attribute}
    />
  )
}
