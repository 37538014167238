import { useCurrentRefinements } from 'react-instantsearch-core'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { uniq } from 'lodash'
import { inferLanguageLabelByLanguageCode } from 'src/utils/plain/inferLanguageLabelByLanguageCode'

import { MultiselectFilterChips } from '@/components/MultiselectFilterChip'
import { getLanguageAndCountry } from '@/utils'

import { FilterChipsResetButton } from './FilterChipsResetButton'

export const FilterChips = () => {
  const { locale } = useRouter()
  const { languageCode } = getLanguageAndCountry(locale)

  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.filtersPanel' })
  const { t: tDA } = useTranslation('dam-assets')

  const { items } = useCurrentRefinements()
  const refinedAttributes = uniq(items.map((item) => item.attribute))

  const filterChipProps: Parameters<typeof MultiselectFilterChips>[0][] = [
    {
      label: t('filters.documentType'),
      attribute: 'documentTypes',
      hasSearch: true,
      translateOptionFn: (option) => tDA(`assetCategories.${option}`),
      includedAttributesForAnalytics: ['documentTypes'],
    },
    {
      label: t('filters.productCategory'),
      attribute: `productCategories.lvl0.${languageCode}`,
      hasSearch: true,
      includedAttributesForAnalytics: [`productCategories.lvl0.${languageCode}`],
    },
    {
      label: t('filters.fieldOfApplication'),
      attribute: `fieldsOfApplication.${languageCode}`,
      hasSearch: true,
      includedAttributesForAnalytics: ['division'],
    },
    {
      label: t('filters.areaOfApplication'),
      attribute: `areasOfApplication.${languageCode}`,
      hasSearch: true,
      includedAttributesForAnalytics: ['division'],
    },
    {
      label: t('filters.division'),
      attribute: 'division',
      hasSearch: false,
      translateOptionFn: (option) => tDA(`divisions.${option}`),
      includedAttributesForAnalytics: ['division'],
    },
    {
      label: t('filters.language'),
      attribute: 'language',
      hasSearch: false,
      translateOptionFn: inferLanguageLabelByLanguageCode(languageCode),
      includedAttributesForAnalytics: [],
    },
  ]

  return (
    <>
      {
        // facets with refinements come first
        filterChipProps.map((props) => {
          if (!refinedAttributes.includes(props.attribute)) return null
          return <MultiselectFilterChips key={props.attribute} {...props} />
        })
      }
      {
        // facets without refinements
        filterChipProps.map((props) => {
          if (refinedAttributes.includes(props.attribute)) return null
          return <MultiselectFilterChips key={props.attribute} {...props} />
        })
      }
      <FilterChipsResetButton dataCy="filter-chips-reset-all-filters-button" />
    </>
  )
}
